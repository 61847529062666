/* ./src/index.css */
@tailwind base;
@tailwind components;
@tailwind utilities;



:root {
    --dineup-base-color: black
}



